// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: true,
  target: 'qa',
  useEmulators: false,
  logLevel: NgxLoggerLevel.WARN,
  firebaseConfig: {
    apiKey: 'AIzaSyBCPj8yK3UVvwk4KKLKz-_kdFMm26ty69E',
    authDomain: 'arbitral-qa-5ae6d.firebaseapp.com',
    projectId: 'arbitral-qa-5ae6d',
    storageBucket: 'arbitral-qa-5ae6d.appspot.com',
    messagingSenderId: '969035949524',
    appId: '1:969035949524:web:da2c9973e4b205cbada241',
    measurementId: 'G-7J52DG4DCQ'
  },
  vapidKey: 'BM3pN1M6CAtgFZx7Ad8REeDgbwkM1WIiaPf5rO1pkMvltszBsUcPshfQ707ArLdB2eLRGxRfMiqSP3ABdC67kWs',
  algoliaConfig: {
    appId: 'VKMIYB88G2',
    apiKey: '71e7f28f8fb186b7452184878dd71f8b'
  },
  tenorKey: 'AIzaSyBCPj8yK3UVvwk4KKLKz-_kdFMm26ty69E',
  recaptcha3SiteKey: '6Ld8C9kiAAAAAJbmaWGkiON-jrJQbwB0yUxXotkS',
  sentryConf: {
    dsn: 'https://4bb0077faa3a437e8f487a22aa1ce8e4@o4504090760183808.ingest.sentry.io/4504090769817600',
    tracingOrigins: ['localhost', 'https://qa.arbitral.app']
  }
};
